import React, {useCallback, useEffect, useState} from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Btn from '../../components/Btn';
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import xIcon from '../../assets/xicon.svg';
import convertJSON from '../../utilities/convertJSON';
import GenericTable from '../table/GenericTable';

const SyncSection = () => {
    const { state, dispatch } = useStore();
    const [displayQBSync, setDisplayQBSync] = useState(false);
    const [displayBCSync, setDisplayBCSync] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quotes, setQuotes] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);
    const [action, setAction] = useState({});
    const [displayConflict, setDisplayConflict] = useState(false);
    const [conflicts, setConflicts] = useState({});
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const getProducts = async () => {
        try {
            const res = await fetch(`${config.base_api}/products/await`, {headers});
            const data = await convertJSON(res);

            // Determine which sync buttons to display
            let shouldDisplayQBSync = false;
            let shouldDisplayBCSync = false;

            for (let i = 0; i < data.length; i++) {
                const product = data[i];
                if (product.needs_sync_qb) {
                    shouldDisplayQBSync = true;
                }
                if (product.needs_sync_bc) {
                    shouldDisplayBCSync = true;
                }
                if (shouldDisplayBCSync && shouldDisplayQBSync) {
                    break;
                }
            }

            setDisplayQBSync(shouldDisplayQBSync);
            setDisplayBCSync(shouldDisplayBCSync);
            setProducts(data);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
            console.log(error);
        }
    };

    const getQuotes = async () => {
        try {
            const res = await fetch(`${config.base_api}/quote/needSync`, {headers});
            const data = await convertJSON(res);

            // All quotes require syncing in Quickbooks
            if (data.length > 0) {
                setDisplayQBSync(true);
            }

            setQuotes(data);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
            console.log(error);
        }
    };

    function modifyCreatedAt(data) {
        for (const key in data) {
            if (data?.hasOwnProperty(key) && Array?.isArray(data[key])) {
                data[key]?.forEach(obj => {
                    if (obj?.hasOwnProperty('createdAt')) {
                        const date = new Date(obj.createdAt);
                        const cstDate = date.toLocaleString('en-US', { timeZone: 'America/Chicago' });
                        obj.createdAt = cstDate;
                    }
                });
            }
        }
    }

    const checkForDuplicates = async () => {
        try {
            const res = await fetch(`${config.base_api}/quote/findDuplicates`, {headers});

            if (res.ok) {
                let data = await convertJSON(res);
                if (Object.keys(data)?.length > 0) {
                    modifyCreatedAt(data);
                    setConflicts(data);
                    setDisplayConflict(true);
                } else {
                    await syncQuickbooks();
                }
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Duplicates Checked'}});
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Error checking for duplicates', isError: true}});
                await syncQuickbooks();
            }


        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Could Not Check Duplicates'}});
            await syncQuickbooks();
        }
    };

    //Quickbooks sync occurs after user logs in to quickbooks
    const syncQuickbooks = async () => {
        setDisplayConflict(false);
        setReloadPage(true);
        window.open(`${config.base_api}/quickbooks/syncProducts/authURI/allItems`);
    };

    //BigCommerce sync
    const syncBigCommerce = async () => {
        setReloadPage(true);
        window.open(`${config.base_api}/bigCommerce`);
    };

    //Remove Quote From Sync Queue
    const removeQuote = async (id) => {
        try {
            const res = await fetch(`${config.base_api}/quote/syncQB/${id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({needs_sync_qb: false}),
            });

            await convertJSON(res);

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: {message: `Quote ${id} Deleted`}});
                getQuotes();
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Could Not Delete Quote ${id}`}});
        }
    };

    const removeProduct = async (sku) => {
        try {
            const res = await fetch(`${config.base_api}/products/remove/${sku}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({
                    needs_sync_qb: false,
                    needs_sync_bc: false,
                })
            });

            await convertJSON(res);

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: {message: `Product ${sku} Deleted`}});
                getProducts();
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Could Not Delete Product ${sku}`}});
        }
    };

    const getSyncItems = async () => {
        try {
            await getProducts();
            await getQuotes();
            setLoading(false);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
            console.log(error);
        }
    };

    useEffect(() => {
        getSyncItems();
    }, []);

    useEffect(() => {
        (async () => {
            if (action?.playground_boss_sku && action?.modified_action) {
                try {
                    const res = await fetch(`${config.base_api}/products/updateAction/${action?.playground_boss_sku}`, {
                        method: 'PATCH',
                        headers,
                        body: JSON.stringify({modified_action: action?.modified_action}),
                    });
                    setAction({});
                    if (res.ok) {
                        dispatch({ type: ACTIONS.TOAST, payload: {message: `Product ${action?.playground_boss_sku} changed to ${action?.modified_action}`}});
                        
                    }
                } catch (error) {
                    setAction({});
                    dispatch({ type: ACTIONS.TOAST, payload: {message: `Could not modify product action.`}});
                }
            }
        })();
    }, [action]);


    if (loading) {
        return (
            <div id="syncSection">
                <div id="syncButtons"></div>
                <h5>Awaiting Sync</h5>
                <div className="quotesTable">
                    <LoadingInline />
                </div>
            </div>
        );
    }

    const hideConflict = () => {
        setDisplayConflict(false);
    }

    const columns = [
        { key: 'quote_number', label: 'Quote Number', headerClass: 'text-center' },
        { key: 'deal_name', label: 'Deal Name', headerClass: 'text-center' },
        { key: 'prepared_by_name', label: 'Prepared By', headerClass: 'text-center'},
        { key: 'createdAt', label: 'Created At', headerClass: 'text-center' },
    ]
    /*
<th>Quote Number</th>
                                            <th>Deal Name</th>
                                            <th>Customer Name</th>
                                            <th>Created At</th>
    */

    return (
        <div id="syncSection">
            <div id="syncButtons">
                {quotes.length === 0 && products.length === 0 ? (
                    <div></div>
                ) : !reloadPage ? (
                    <div id="syncButtonsContainer">
                        {displayQBSync && <Btn title="Sync Quickbooks" onClick={checkForDuplicates} 
                        btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background'/>}
                        {displayBCSync && <Btn title="Sync BigCommerce" onClick={syncBigCommerce} 
                        btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background'/>}
                    </div>
                ) : (
                    <b>
                        <p>Sync in Progress, refresh page to enable buttons</p>
                    </b>
                )}
            </div>
            <h5>Awaiting Sync</h5>
            <br></br>
            <div className="quotesTable shade-odd-rows">
                <table id="syncTable">
                    <tbody>
                        {quotes.length === 0 && products.length === 0 ? (
                            <tr>
                                <td colSpan="3">Nothing is ready to sync.</td>
                            </tr>
                        ) : (
                            <tr>
                                <th className='text-start'>Item</th>
                                <th className='text-start'>Name</th>
                                <th className='text-start'>Type</th>
                                <th className='text-start'>Needs Sync</th>
                                <th className='text-start'>Action</th>
                                <th className='text-start'>Remove</th>
                            </tr>
                        )}
                        {quotes ? (
                            quotes.map((quote) => (
                                <tr>
                                    <td>{quote.quote_number}</td>
                                    <td>{quote.deal_name}</td>
                                    <td>Quote</td>
                                    <td>
                                    {`Needs sync with: ${
                                            quote.needs_sync_qb 
                                                ? 'QB'
                                                : ''
                                        }`}
                                    </td>
                                    <td>N/A</td>
                                    <td className = "pointer" onClick={() => removeQuote(quote.id)}>
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="1.8rem"
                                                viewBox="0 0 24 24">
                                                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                                            </svg>
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <div></div>
                        )}
                        {products ? (
                            products.map((product) => (
                                <tr>
                                    <td>{product.playground_boss_sku}</td>
                                    <td>{product.product_name}</td>
                                    <td>Product</td>
                                    <td>
                                        {`${
                                            product.needs_sync_qb && product.needs_sync_bc
                                                ? 'QB, BC'
                                                : product.needs_sync_qb
                                                ? 'QB'
                                                : product.needs_sync_bc
                                                ? 'BC'
                                                : ''
                                        }`}
                                    </td>
                                    <td>
                                        {product?.modified_action === 'delete' ? <p>Delete</p>
                                        : (product?.modified_action === 'create' || product?.modified_action === 'update') 
                                        ? <select className='pad-xs pad-r-lg border-radius-xs' onChange={(e) => setAction({playground_boss_sku: product?.playground_boss_sku, modified_action: e.target.value, original_action: product?.modified_action})}>
                                            <option selected={product?.modified_action === 'create'} value='create'>Create</option>
                                            <option selected={product?.modified_action === 'update'} value='update'>Update</option>
                                         </select>
                                         : <p>N/A</p>
                                        }
                                    </td>
                                    <td className = "pointer" onClick={() => removeProduct(product.playground_boss_sku)}>
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="1.8rem"
                                                viewBox="0 0 24 24">
                                                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                                            </svg>
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <div></div>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={`${displayConflict ? "pad-b-xlg conflict-modal border-radius-xs b-none height-30 overflow-vertical-scroll" : "hide"}`}>
                <button onClick={hideConflict} className='bg-white b-none close-button'>
                <img src={xIcon} alt="close button" className='pointer' />
                </button>
                <div className="pad-xlg margin-t-lg margin-r-md margin-l-md width-100">
                    <h2 className=" text-bold">Sync Conflicts</h2>
                    <br/>
                    {Object.keys(conflicts)?.map(conflictKey => (
                        <div className="margin-b-xxlg margin-t-xxlg">
                            <h5 className="margin-t-xxlg margin-b-lg">{conflicts[conflictKey][0]?.deal_name}</h5>
                            <GenericTable columns = {columns} data={conflicts[conflictKey]} />
                        </div>
                    ))}

                    <div className='flex-row justify-end margin-b-xxlg'>
                        <Btn title="Stop Sync" onClick={() => setDisplayConflict(false)} btnClass = 'margin-r-md darken-background text-bold bg-white text-red pad-t-md pad-b-md  no-wrap justify-center b2-red box-shadow border-radius-xs pointer' />
                        <Btn title="Continue Sync" onClick={() => syncQuickbooks()} btnClass="margin-l-md pad-t-md pad-b-md  justify-center box-shadow bg-white text-green text-bold b2-green darken-background" />
                    </div>
                    <br/>
                </div>
            </div>
            <div id="page-mask" className={`${(displayConflict) ? "" : "hidden"}`}></div>

        </div>
    );
};

export default SyncSection;
