import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa'; // Import the checkmark icon

const GenericTable = ({ columns, data, actions, getCellClassName, savedCells, expandableKey = "", expandableColumns, expandableData, style, actionHeader, noAction }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  // Fallback function if getCellClassName is not provided
  const defaultGetCellClassName = () => '';

  const effectiveGetCellClassName = getCellClassName || defaultGetCellClassName;

  // Function to determine if a cell has been saved
  const isCellSaved = (rowIndex, columnKey) => {
    // Check if savedCells is not what's expected
    if (typeof savedCells !== 'object' || savedCells === null) {
      return false; // Add a fallback return
    }

    const key = `${rowIndex}-${columnKey}`;
    return !!savedCells[key];
  }

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  const isCellExpandable = (key) => expandableKey === key;

  return (
    <div className={`component-card box-shadow bg-white overflow-scroll overflow-horizontal-scroll ${style?.continuousTable ? 'border-radius-btm-none' : 'border-radius-md'} ${style?.continuedTable ? 'border-radius-top-none' : 'border-radius-md'}`} style={{ boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.1)', ...((style?.continuousTable || style?.continuedTable) ? {} : { maxHeight: '400px' }) /*maxHeight: '400px'*/ }}>
      <table className="width-100 border-collapse layout-fixed">
        <thead className="bg-light-gray position-sticky sticky-underline-orange">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={`text-center text-med ${column.headerClass || ''}`} style={{ backgroundColor: '#f8f9fa' }}>
                {column.tooltip &&
                  <span className="tooltip tooltip-icon vertical-align-middle margin-r-xs">
                    <span className="tooltiptext double-orange-border line-height-sm modal-tooltip modal-tooltiptext">
                      {column.tooltip_text}
                    </span>
                  </span>
                }
                {column.label}
              </th>
            ))}
            {noAction ? null : actions ? <th className={"text-center text-med  " + (actionHeader?.headerClass ? actionHeader?.headerClass : '')}>Actions</th>
                    : <th className=""></th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <>
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-light-gray'}>
                {columns.map((column, colIndex) => {
                  // Determine if a custom renderCell function is provided, else default rendering
                  let cellContent = column.renderCell ? column.renderCell(row, column.key) : row[column.key];
                  if (column.nestedKey) {
                    cellContent = row[column.key][column.nestedKey] || column?.placeholder
                  }
                  const cellClass = `${isCellSaved(row.sku, column.key) ? 'saved-cell' : ''}`;

                  return (
                    <td key={colIndex} className={`cell ${cellClass} ${column.dataClass || ''} text-center pad-tb-lg ${effectiveGetCellClassName(row[column.key], column.key)}`} onClick={isCellExpandable(column.key) ? () => toggleRowExpansion(rowIndex) : null} style={isCellExpandable(column.key) ? { cursor: 'pointer' } : {}}>
                      {expandedRow === rowIndex
                        ? <strong>{cellContent}</strong>
                        : cellContent
                      }
                      {isCellExpandable(column.key) && <span>{`${expandedRow === rowIndex ? "\u25B2" : "\u25BC"}`}</span>}
                      {/* Show saved indicator if this cell has been saved */}
                      {isCellSaved(row.sku, column.key) && <FaCheck className="checkmark-icon" />}
                    </td>
                  );
                })}
                {noAction ? null : actions ? (
                  <td className="actions">
                    <div className="flex"> {/* Apply the "flex" class here to align buttons side by side */}
                      {actions(row)?.map((action, actionIndex) => (
                        <button
                          id={action?.id || ''}
                          onMouseDown={() => {
                            if (typeof action?.onMouseDown == 'function') action?.onMouseDown()
                          }}
                          key={actionIndex}
                          onClick={() => action.handler(row)}
                          className={`btn btn-sm ${action.buttonClass}`}
                          style={{ marginRight: '5px', borderRadius: '5px' }}
                        >
                          {action.label}
                        </button>
                      ))}
                    </div>
                  </td>
                  )
                  : (<td className="actions empty-action"><p>&nbsp;&nbsp;</p></td>)
                }
              </tr>
              {expandedRow === rowIndex && expandableKey && (
                <tr>
                  <td colSpan={columns.length + (actions ? 1 : 0)}>
                    <div className="component-card box-shadow border-radius-md bg-white overflow-scroll overflow-horizontal-scroll" style={{ boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.1)', maxHeight: '200px', marginTop: '10px' }}>
                      <table className="width-100 border-collapse layout-fixed nested-table">
                        <thead className="bg-light-gray">
                          <tr>
                            {expandableColumns?.map((expandableColumn, index) => (
                              <th key={index} className={`text-center text-med ${expandableColumn.headerClass || ''}`} style={{ backgroundColor: '#f8f9fa' }}>{expandableColumn.label}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {expandableData?.map((expandableRow, expandableRowIndex) => (
                            row.id === expandableRow.id &&
                            <>
                              <tr key={expandableRowIndex}>
                                {expandableColumns.map((expandableColumn, colIndex) => (
                                  <td
                                    key={colIndex}
                                    className={`cell ${expandableColumn.dataClass || ''} text-center ${effectiveGetCellClassName(expandableRow[expandableColumn.key], expandableColumn.key)}`}
                                  >
                                    {expandableRow[expandableColumn.key]}
                                  </td>
                                ))}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={columns.length + (actions ? 1 : 0)} className="text-center pad-lg" style={{ color: '#6c757d' }}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTable;
