import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import DashboardNavBar from '../components/navBar/DashboardNavBar';
import StockSheetDashboard from './StockSheetDashboard'; // Adjust path as necessary
import OverviewDataDashboard from './OverviewDataDashboard';
// Import other dashboards as necessary
import config from '../config'; // Ensure you have your configuration with the API's base URL
import authHeaders from '../utilities/authHeaders'; // Function to get auth headers
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';


const dashboards = [
    { name: "Stock Sheet", path: "stockSheet", selected: true },
    { name: "Overview Graphs", path: "overviewGraphs", selected: false, restricted: true, access: ['admin', 'ops'] },
];

const options = { 
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
    hour12: true, 
    hour: 'numeric', 
    minute: 'numeric', 
    month: 'numeric', 
    day: 'numeric', 
    year: 'numeric'
};

const Zoho = () => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [syncTableDate, setSyncTableDate] = useState(null);
    const [syncChartDate, setSyncChartDate] = useState(null);

    useEffect(() => {
        // Redirect to the default selected tab if the current path doesn't match any dashboard path
        if (location.pathname === "/operations/zoho/" || location.pathname === "/operations/zoho") {
            navigate(dashboards?.find(board => board?.selected)?.path);
        }
    }, [location.pathname]); // Re-run when the path changes

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
                const response = await fetch(`${config.base_api}/zoho/lastSync`, { method: 'GET', headers });

                if (!response.ok) throw new Error('Failed to fetch stock sheet data');

                const data = await response.json();

                setSyncTableDate(data[0]?.chart_data?.date);
                setSyncChartDate(data[1]?.chart_data?.date);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, []);

    const displayDate = () => {
        if (dashboards[0]?.path == 'stockSheet') {
            const date = new Date(syncTableDate);
            const localTimeString = date?.toLocaleString('en-US', options);
            return localTimeString || '';
        }
        else if (dashboards[1]?.path == 'overviewGraphs') {
            const date = new Date(syncChartDate);
            const localTimeString = date?.toLocaleString('en-US', options);
            return localTimeString || '';
        }
    }

    return (
        <div id="projectTablePage" className='pad-lg'>
            <h3 className='text-center text-bold text-lrg pad-l-xs'>Stock Sheet</h3>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <div className="dashboardNavbarContainer" style={{ display: 'inline-block', width: 'auto' }}>
                    <DashboardNavBar dashboards={dashboards} auth={state?.auth} />
                </div>
            </div>
            <p className='pad-l-md'>Last sync: {displayDate()}</p>

            <div className='config-items height-100 pad-md'>
                <Routes>
                    <Route path={dashboards[0].path} element={<StockSheetDashboard />} />
                    <Route path={dashboards[1].path} element={<OverviewDataDashboard />} />
                    {/* Add more routes as needed */}
                    <Route path="*" element={<div>Route not found</div>} />

                </Routes>
            </div>
        </div>
    );
};

export default Zoho;
