import React, { useEffect, useState } from 'react';
import GenericTable from '../components/table/GenericTable'; // Adjust the import path as necessary
import config from '../config'; // Ensure you have your configuration with the API's base URL
import authHeaders from '../utilities/authHeaders'; // Function to get auth headers
import { useStore } from '../StoreProvider';

const StockInternalUse = ({ access }) => {
  const { state } = useStore();
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState(null); // Track the row being edited
  const [weightOfUnit, setWeightOfUnit] = useState(null); // New state to track the edited value
  const [notes, setNotes] = useState(null); // New state to track the edited value
  const [savedCells, setSavedCells] = useState({}); // Tracks saved cells, e.g., { 'rowIndex-columnKey': true }
  const [cancel, setCancel] = useState(false);
  const [onHandNatural, setOnHandNatural] = useState(null);
  const [onHandPrimary, setOnHandPrimary] = useState(null);

  let allowed = false;
  if (access?.includes('admin') && +state?.auth?.isAdmin) {
      allowed = true;
  } else if (access?.includes('ops') && +state?.auth?.isOps) {
      allowed = true;
  } else if (access?.includes('sales') && +state?.auth?.isSales) {
      allowed = true;
  }
  
  const handleBlur = (sku, columnKey, value) => {
    console.log('cancel:', cancel)
    if (!cancel) {
      handleSave(sku, columnKey, value);
    } 
    else {
      setCancel(false);
    }
  };

  const handleSave = async (sku, columnKey, newValue) => {
    // Implement your save logic here
    if (columnKey === 'weightOfUnit') {
        setEditingRow(null); // Exit editing mode
        await updateWeightOfUnitDB(sku, newValue);

        // Optionally, update the data state with the new value
        const updatedData = data.map(item => item.sku === sku ? {...item, weightOfUnit: newValue} : item);
        setData(updatedData);
    }
    else if (columnKey === 'notes') {
        setEditingRow(null); // Exit editing mode
        await updateNotesDB(sku, newValue);
        const updatedData = data.map(item => item.sku === sku ? {...item, notes: newValue} : item);
        setData(updatedData);
    }
    else if (columnKey === 'onHandNatural') {
        setEditingRow(null); // Exit editing mode
        await updateOnHandNaturalDB(sku, newValue);
        const updatedData = data.map(item => item.sku === sku ? {...item, onHandNatural: newValue} : item);
        setData(updatedData);
    } 
    else if (columnKey === 'onHandPrimary') {
        setEditingRow(null); // Exit editing mode
        await updateOnHandPrimaryDB(sku, newValue);
        const updatedData = data.map(item => item.sku === sku ? {...item, onHandPrimary: newValue} : item);
        setData(updatedData);
    }
        
    // For demonstration, we assume the save is immediate and successful

    // Update the savedCells state to mark this cell as saved
    const cellId = `${sku}-${columnKey}`;
    setSavedCells({ ...savedCells, [cellId]: true });

    // Optionally, clear the saved indicator after a delay
    setTimeout(() => setSavedCells((prev) => ({ ...prev, [cellId]: false })), 2000); // Clear after 2 seconds
  };

  // Updated columns with correct key accessors
  const columns = [
    { key: 'productName', label: 'Product Name', headerClass: 'pad-l-xxlg text-left', dataClass: '200px text-left pad-l-xxlg' },
    { key: 'sku', label: 'Product \nCode/SKU', headerClass: 'text-center', dataClass: '200px' }, // Using `sku` as key
    { key: 'onHandNatural', label: 'On Hand \nNatural', headerClass: 'text-center', dataClass: '200px',
      renderCell: (row) => {
        if (editingRow === row.sku) {
          return (
            <input
              type="number"
              className="number-input"
              value={onHandNatural}
              onChange={(e) => setOnHandNatural(e.target.value)} // Keep the input controlled
              onBlur={(e) => handleBlur(row.sku, 'onHandNatural', Math.round(e.target.value))} // Save the value on blur
            />
          );
        } else {
          return row.onHandNatural;
        }
      }
    },
    { key: 'onHandPrimary', label: 'On Hand \nPrimary', headerClass: 'text-center', dataClass: '200px',
      renderCell: (row) => {
        if (editingRow === row.sku) {
          return (
            <input
              type="number"
              className="number-input"
              value={onHandPrimary}
              onChange={(e) => setOnHandPrimary(e.target.value)} // Keep the input controlled
              onBlur={(e) => handleBlur(row.sku, 'onHandPrimary', Math.round(e.target.value))} // Save the value on blur
            />
          );
        } else {
          return row.onHandPrimary;
        }
      }
    },
    { key: 'ages', label: 'Ages', headerClass: 'text-center' },
    {
        key: 'weightOfUnit', 
        label: 'Weight \nof Unit', 
        headerClass: 'text-center',
        renderCell: (row) => {
          if (editingRow === row.sku) {
            return (
              <input
                type="number"
                className="number-input"
                value={weightOfUnit}
                onChange={(e) => setWeightOfUnit(e.target.value)} // Keep the input controlled
                onBlur={(e) => handleBlur(row.sku, 'weightOfUnit', Math.round(e.target.value))} // Save the value on blur
              />
            );
          } else {
            return row.weightOfUnit;
          }
        }
      },
      {
        key: 'notes',
        label: 'Notes',
        headerClass: 'text-center',
        renderCell: (row) => {
          if (editingRow === row.sku) {
            return (
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)} // Keep the input controlled
                onBlur={(e) => handleBlur(row.sku, 'notes', e.target.value)} // Save the value on blur
              />
            );
          } else {
            return row.notes;
          }
        }
      }
  ];

const editSaveActions = (row) => {
  // Actions when a row is being edited
  if (editingRow === row.sku) {
    return [
      {
        label: 'Cancel',
        onMouseDown: () => setCancel(true), // Set cancel state to true on mouse down
        handler: () => {
          setEditingRow(null); // Exit editing mode without saving
          setWeightOfUnit(null); // Reset edited value (optional, based on your state management)
          setNotes(null);
          setOnHandNatural(null);
          setOnHandPrimary(null);
        },
        buttonClass: 'btn-cancel',
      }
    ];
  } 
  // Default action when not editing
  else {
    return [
      {
        label: 'Edit',
        handler: () => {
          setEditingRow(row.sku); // Set this row to be in editing mode
          setWeightOfUnit(row.weightOfUnit); // Initialize editValue with this row's value
          setNotes(row.notes);
          setOnHandNatural(row.onHandNatural);
          setOnHandPrimary(row.onHandPrimary);
          setCancel(false); // Reset cancel state
        },
        buttonClass: 'btn-edit',
      }
    ];
  }
};

  // Adjusted to be a function that accepts a row and returns actions for that row
  const actions = (row) => {
    return [
      ...editSaveActions(row),
    ];
  };

  const updateNotesDB = async (sku, newValue) => {
    try {
        if (data?.find(item => item?.sku === sku)?.inTransit) {
          // call other function to save to ZohoInventoryInTransit table
          await updateNotesInTransit(sku, newValue);
          return;
        }
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/internalUse/notes/${sku}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ notes: newValue }),
        });

        if (!response.ok) throw new Error('Failed to update notes');

        const updatedData = await response.json();
        console.log('Updated notes:', updatedData);
    } catch (error) {
        console.error('Error updating notes:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
    }
  };

  const updateNotesInTransit = async (sku, newValue) => {
    try {
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/inventoryInTransit/notes/${sku}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ notes: newValue }),
        });

        if (!response.ok) throw new Error('Failed to update notes');

        const updatedData = await response.json();
        console.log('Updated notes:', updatedData);
    } catch (error) {
        console.error('Error updating notes:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
    }
  };

  const updateOnHandNaturalDB = async (sku, newValue) => {
    try {
      const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
      const response = await fetch(`${config.base_api}/zoho/stockSheet/internalUse/onHandNatural/${sku}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ onHandNatural: newValue }),
      });

      if (!response.ok) throw new Error('Failed to update on hand natural');

      const updatedData = await response.json();
      console.log('Updated on hand natural:', updatedData);
    } catch (error) {
      console.error('Error updating on hand natural:', error);
      // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  const updateOnHandPrimaryDB = async (sku, newValue) => {
    try {
      const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
      const response = await fetch(`${config.base_api}/zoho/stockSheet/internalUse/onHandPrimary/${sku}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ onHandPrimary: newValue }),
      });

      if (!response.ok) throw new Error('Failed to update on hand primary');

      const updatedData = await response.json();
      console.log('Updated on hand primary:', updatedData);
    } catch (error) {
      console.error('Error updating on hand primary:', error);
      // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  const updateWeightOfUnitDB = async (sku, newValue) => {
    try {
      if (data?.find(item => item?.sku === sku)?.inTransit) {
        // call other function to save to ZohoInventoryInTransit table
        await updateWeightOfUnitInTransiit(sku, Math.round(+newValue));
        return;
      }

      const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
      const response = await fetch(`${config.base_api}/zoho/stockSheet/internalUse/weightOfUnit/${sku}`, { 
        method: 'PUT', 
        headers,
        body: JSON.stringify({ weightOfUnit: Math.round(+newValue) }),
      });

      if (!response.ok) throw new Error('Failed to update weight of unit');

      const updatedData = await response.json();
      console.log('Updated weight of unit:', updatedData);
    } catch (error) {
      console.error('Error updating weight of unit:', error);
      // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  const updateWeightOfUnitInTransiit = async (sku, newValue) => {
    try {
      const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
      const response = await fetch(`${config.base_api}/zoho/stockSheet/inventoryInTransit/weightOfUnit/${sku}`, { 
        method: 'PUT', 
        headers,
        body: JSON.stringify({ weightOfUnit: newValue }),
      });

      if (!response.ok) throw new Error('Failed to update weight of unit');

      const updatedData = await response.json();
      console.log('Updated weight of unit:', updatedData);
    } catch (error) {
      console.error('Error updating weight of unit:', error);
      // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  useEffect(() => {
    const fetchStockInternalUseData = async () => {
      try {
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/internalUse`, { method: 'GET', headers });

        if (!response.ok) throw new Error('Failed to fetch stock sheet data');

        const fetchedData = await response.json();

        // Transform data to match the columns format and adjust according to the available data keys
        const transformedData = fetchedData.map(item => ({
          productName: item.productName, // Assuming this key is correct
          sku: item.base_sku, // Using `sku` as received from the data
          onHandNatural: item.on_hand_natural, // Assuming this key is correct
          onHandPrimary: item.on_hand_primary, // Assuming this key is correct
          ages: item.ages, // Removed transformation for age and weightOfUnit
          weightOfUnit: item.weight_of_unit,
          notes: item.notes,
          inTransit: !!item?.in_transit,
          // Removed transformation for age and weightOfUnit
        }));

        setData(transformedData);
      } catch (error) {
        console.error('Error fetching stock sheet data:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
      }
    };

    fetchStockInternalUseData();
  }, []); // Empty array means this effect runs once on component mount

  const getCellClassName = (value, column) => {
    if (column !== 'onHandNatural' && column !== 'onHandPrimary') return '';
    if (+value === 0) return 'cell-zero';
    else if (+value < 0) return 'cell-negative';
    else return '';
  };

  const style = { continuousTable: true };

  return (
    // <div className="stockSheetDashboard">
      <GenericTable columns={columns} data={data} actions={allowed ? actions : null} getCellClassName={getCellClassName} savedCells={savedCells} style={style}/>
    // </div>
  );
};

export default StockInternalUse;
